@tailwind base;
@tailwind utilities;
@tailwind components;

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

/* RESET */
.react-datepicker {
  font-family: Inter, sans-serif;
  @apply border-transparent;
}

.react-datepicker__header {
  @apply bg-transparent border-transparent;
}

.react-datepicker__day {
  @apply outline-none;
}

/* LAYOUT */
.react-datepicker,
.react-datepicker__month-container {
  @apply w-full;
}

.react-datepicker__header {
  @apply pt-0;
}

.react-datepicker__navigation {
  @apply top-0.5;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  @apply flex justify-center h-4 w-8;
}

.react-datepicker__current-month {
  @apply mb-4;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply grid grid-cols-8 text-center;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__week-number {
  @apply m-auto w-10 h-10 leading-40px;
}

.react-datepicker__week {
  @apply mt-2;
}

.react-datepicker__month {
  @apply m-0;
}

/* STYLE */
.react-datepicker__day-name {
  @apply text-gray-300;
}

.react-datepicker__current-month {
  @apply text-base;
}

.react-datepicker__day-name,
.react-datepicker__day {
  @apply rounded-full;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply rounded-full;
}

/* THEME */
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-action-400 transition-colors;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  @apply bg-action-500;
}

.swiper-pagination-bullet {
  @apply hidden sm:block;
}
