@tailwind base;
@tailwind utilities;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@layer base {
  html {
    font-size: 100%;
  }

  body {
    @apply antialiased h-full font-display leading-17px text-base text-gray-400 bg-white;
  }

  input,
  input::placeholder,
  select,
  textarea {
    @apply leading-13px text-sm;
  }

  input::placeholder {
    overflow: visible;
  }

  select {
    appearance: none;
  }
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

#root {
  height: 100%;
}

:root {
  --height-header: 66px;
  --width-menu: 375px;
  height: 100%;
}

@screen xl {
  :root {
    --width-menu: 300px;
    --height-header: 90px;
  }
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

/* SWIPER */

.swiper-container {
  @apply flex flex-col;
}

.swiper-pagination {
  z-index: 1;
  @apply order-2 flex pt-4 mx-auto space-x-1;
}

.swiper-wrapper {
  @apply order-1;
}

.swiper-pagination-bullet {
  @apply w-4 h-0.5 rounded-full bg-gray-200;
}

.swiper-pagination-bullet-active {
  @apply bg-gray-500;
}

/* RATING */
.rating {
  width: 106px;
  height: 106px;
}

.rating-faq {
  width: 68px;
  height: 68px;
}
